export function getLegalLinks({ useUrl }) {
  const nuxtApp = useNuxtApp();
  const t = nuxtApp.$i18n.t;

  return [
    {
      text: t('t_FOOTERDATAPROTECTIONNOTICE'),
      attrs: { href: useUrl.getSecurityPrivacyUrl() },
    },
    {
      text: t('t_FOOTERTERMSANDLEGAL'),
      attrs: { href: useUrl.getGenericPageUrl('hosteltermsandconditions') },
    },
    {
      text: t('t_FOOTERLINKCOUPONS'),
      attrs: { href: useUrl.getCouponsUrl('coupons-terms-conditions') },
    },
    {
      text: t('t_VOUCHERS'),
      attrs: { href: useUrl.getVouchersUrl('vouchertermsandconditions') },
    },
    {
      text: t('t_FOOTERWEBSITETESTING'),
      attrs: { href: useUrl.getGenericPageUrl('website-testing') },
    },
    {
      text: t('t_FOOTERSITEMAP'),
      attrs: { href: useUrl.getGenericPageUrl('sitemap') },
    },
  ];
}
