export default {
  blogAllowedLangs: ['en', 'fr', 'de', 'es', 'it', 'br'],
  insuranceAllowedLangs: ['en'],
  socialNetworks: {
    en: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    fr: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    de: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    es: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    it: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    pt: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    br: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    sv: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    pl: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    fi: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    da: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    nl: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    no: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    cs: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    cz: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    ru: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    tr: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    zh: ['weibo'],
    ja: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    jp: ['facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
    ko: ['naver', 'naverblog', 'facebook', 'twitter', 'instagram', 'youtube', 'pinterest', 'tiktok'],
  },
};
