export function getCommonLinks({ useUrl }) {
  const nuxtApp = useNuxtApp();
  const t = nuxtApp.$i18n.t;
  const locale = nuxtApp.$i18n.locale;

  const allLinks = {
    accommodation: {
      header: t('t_ACCOMMODATION'),
      links: [
        {
          type: 'property-type',
          text: t('t_HOSTELS'),
          attrs: {
            href: useUrl.getWorldPageUrl({ propertyTypeSlug: t('t_SLUGPROPERTYTYPEHOSTELS') }),
            rel: 'opener',
            target: '_self',
          },
        },
        {
          type: 'property-type',
          text: t('t_HOTELS'),
          attrs: {
            href: useUrl.getWorldPageUrl({ propertyTypeSlug: t('t_SLUGPROPERTYTYPEHOTELS') }),
            rel: 'opener',
            target: '_self',
          },
        },
        {
          type: 'property-type',
          text: t('t_GUESTHOUSE'),
          attrs: {
            href: useUrl.getWorldPageUrl({ propertyTypeSlug: t('t_SLUGPROPERTYTYPEBNB') }),
            rel: 'opener',
            target: '_self',
          },
        },
      ],
    },
    customerSupport: {
      header: t('t_CUSTOMERSUPPORT'),
      links: [
        {
          text: t('t_TALKTOUSTEXT'),
          attrs: { href: `${t('t_CONTACTUSURL')}/requests/new?ticket_form_id=63361` },
        },
        {
          text: t('t_HELP'),
          attrs: { href: t('t_CONTACTUSURL') },
        },
      ],
    },
    quickLinks: {
      header: t('t_QUICKLINKS'),
      links: [
        {
          text: t('t_BLOG'),
          attrs: {
            href: t('t_URLBLOG'),
            rel: 'noopener',
          },
        },
        {
          type: 'guarantee',
          text: t('t_BOOKINGGUARANTEE'),
          attrs: {
            href: useUrl.getGenericPageUrl('guarantee'),
            rel: 'opener',
            target: '_self',
          },
        },
        {
          text: t('t_HOSCARAWARDS'),
          attrs: {
            href: t('t_HOSCARAWARDSURL'),
            rel: 'noopener',
          },
        },
        {
          text: t('t_TOURSEXPERIENCESTEXT'),
          attrs: {
            href: t('t_TOURSEXPERIENCESURL'),
            rel: 'noopener',
          },
        },
        {
          text: t('t_SOLOTRAVELTEXT'),
          attrs: {
            href: t('t_SOLOTRAVELURL'),
            rel: 'noopener',
          },
        },
        {
          text: t('t_STUDENTDISCOUNTTEXT'),
          attrs: {
            href: t('t_STUDENTDISCOUNTURL'),
            rel: 'noopener nofollow noreferrer sponsored',
            target: '_blank',
          },
        },
        locale?.value === 'en'
          ? {
            text: t('t_ROAMIESTEXT'),
            attrs: {
              href: t('t_ROAMIESURL'),
              rel: 'noopener',
            },
          }
          : {},
      ],
    },
    aboutUs: {
      header: t('t_ABOUTUS'),
      links: [
        {
          text: t('t_HWGROUPPLC'),
          attrs: { href: t('t_HWGROUPPLCURL') },
        },
        {
          text: t('t_PRESS'),
          attrs: { href: t('t_PRESSURL') },
        },
        {
          text: t('t_CAREERS'),
          attrs: { href: t('t_CAREERSURL') },
        },
      ],
    },
    workWithUs: {
      header: t('t_WORKWITHUS'),
      links: [
        {
          text: t('t_HOSTELSIGNUP'),
          attrs: { href: t('t_HWSIGNUPURL') },
        },
        {
          text: t('t_AFFILIATEPROGRAM'),
          attrs: { href: t('t_AFFILIATEPROGRAMURL') },
        },
        {
          text: t('t_INBOXLOGIN'),
          attrs: { href: t('t_INBOXLOGINURL') },
        },
      ],
    },
  };

  const getQuickLinks = computed(() => {
    return [
      [allLinks.accommodation, allLinks.customerSupport],
      [allLinks.quickLinks],
      [allLinks.aboutUs, allLinks.workWithUs],
    ];
  });

  return { getQuickLinks: getQuickLinks?.value };
}
